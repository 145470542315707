define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "common": {
      "api-error-messages": {
        "bad-request-error": "The data entered was not in the correct format.",
        "gateway-timeout-error": "The service is being slow. Please try again later.",
        "internal-server-error": "An error occurred, our teams are working on finding a solution. Please try again later.",
        "login-unauthorized-error": "There was an error in the email address or username/password entered.",
        "login-user-blocked-error": "Your account has reached the maximum number of failed login attempts and has been temporarily blocked. Please '<'a href=\"{url}\"'>'contact us'</a>' to unblock it.",
        "login-user-temporary-blocked-error": "You have reached too many failed login attempts. Please try again later or '<'a href=\"{url}\"'>'reset your password here'</a>'."
      },
      "notifications": {
        "generic-error": "An error occurred."
      },
      "words": {
        "no": "No",
        "yes": "Yes"
      }
    },
    "components": {
      "administration": {
        "campaigns-import": {
          "description": "Les campagnes déjà existantes ne seront pas modifiées. Il n'y a pas de contrôle pour empêcher les doublons.",
          "notifications": {
            "success": "Les campagnes ont bien été créées."
          },
          "title": "Création en masse de campagnes",
          "upload-button": "Importer un fichier CSV"
        },
        "organizations-import": {
          "description": "Existing organizations will not be changed.",
          "notifications": {
            "success": "The organizations have been created."
          },
          "title": "Mass creation of organizations",
          "upload-button": "Import CSV file"
        }
      },
      "organizations": {
        "all-tags": {
          "recently-used-tags": "List of tags recently used with {tagName}"
        }
      },
      "users": {
        "user-detail-personal-information": {
          "authentication-method": {
            "should-change-password-status": "Temporary password :"
          }
        }
      }
    },
    "pages": {
      "login": {
        "api-error-messages": {
          "login-no-permission": "You don't have the permission to connect."
        }
      },
      "organizations": {
        "notifications": {
          "errors": {
            "payload-too-large": "Le poids du logo dépasse la limite autorisée de {maxSizeInMegaBytes} Mo. Veuillez réduire le poids du logo et essayer à nouveau."
          }
        }
      },
      "trainings": {
        "training": {
          "targetProfiles": {
            "tabName": "Target Profiles",
            "title": "Rattacher un ou plusieurs profils cibles"
          },
          "triggers": {
            "goal": {
              "alternative-title": "Add a goal threshold to not exceed",
              "edit": {
                "description": "Si l’apprenant réussit les acquis des sujets sélectionnés alors le contenu formatif ne lui sera pas proposé."
              },
              "title": "Goal"
            },
            "prerequisite": {
              "alternative-title": "Add a prerequisite threshold to reach",
              "edit": {
                "description": "Si l’apprenant réussit tous les acquis des sujets sélectionnés alors le contenu formatif lui sera proposé."
              },
              "title": "Prerequisite"
            },
            "tabName": "Triggers"
          }
        }
      }
    }
  }], ["fr", {
    "common": {
      "api-error-messages": {
        "bad-request-error": "Les données que vous avez soumises ne sont pas au bon format.",
        "gateway-timeout-error": "Le service subit des ralentissements. Veuillez réessayer ultérieurement.",
        "internal-server-error": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
        "login-unauthorized-error": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects.",
        "login-user-blocked-error": "Votre compte est bloqué car vous avez effectué trop de tentatives de connexion. Pour le débloquer, '<'a href=\"{url}\"'>'contactez-nous'</a>'.",
        "login-user-temporary-blocked-error": "Vous avez effectué trop de tentatives de connexion. Réessayez plus tard ou cliquez sur '<'a href=\"{url}\"'>'mot de passe oublié'</a>' pour le réinitialiser."
      },
      "notifications": {
        "generic-error": "Une erreur est survenue."
      },
      "words": {
        "no": "Non",
        "yes": "Oui"
      }
    },
    "components": {
      "administration": {
        "campaigns-import": {
          "description": "Les campagnes déjà existantes ne seront pas modifiées. Il n'y a pas de contrôle pour empêcher les doublons.",
          "notifications": {
            "success": "Les campagnes ont bien été créées."
          },
          "title": "Création en masse de campagnes",
          "upload-button": "Importer un fichier CSV"
        },
        "organizations-import": {
          "description": "Les organisations déjà existantes ne seront pas modifiées.",
          "notifications": {
            "success": "Les organisations ont bien été créées."
          },
          "title": "Création en masse d'organisations",
          "upload-button": "Importer un fichier CSV"
        }
      },
      "organizations": {
        "all-tags": {
          "recently-used-tags": "Liste de tags récemment utilisés avec {tagName}"
        }
      },
      "users": {
        "user-detail-personal-information": {
          "authentication-method": {
            "should-change-password-status": "Mot de passe temporaire :"
          }
        }
      }
    },
    "pages": {
      "login": {
        "api-error-messages": {
          "login-no-permission": "Vous n'avez pas les droits pour vous connecter."
        }
      },
      "organizations": {
        "notifications": {
          "errors": {
            "payload-too-large": "Le poids du logo dépasse la limite autorisée de {maxSizeInMegaBytes} Mo. Veuillez réduire le poids du logo et essayer à nouveau."
          }
        }
      },
      "trainings": {
        "training": {
          "targetProfiles": {
            "tabName": "Profils cibles associés",
            "title": "Rattacher un ou plusieurs profils cibles"
          },
          "triggers": {
            "goal": {
              "alternative-title": "Ajouter un objectif à ne pas dépasser",
              "edit": {
                "description": "Si l’apprenant réussit les acquis des sujets sélectionnés alors le contenu formatif ne lui sera pas proposé."
              },
              "title": "Objectif à ne pas dépasser"
            },
            "prerequisite": {
              "alternative-title": "Ajouter un prérequis",
              "edit": {
                "description": "Si l’apprenant réussit tous les acquis des sujets sélectionnés alors le contenu formatif lui sera proposé."
              },
              "title": "Prérequis"
            },
            "tabName": "Déclencheurs"
          }
        }
      }
    }
  }]];
  _exports.default = _default;
});